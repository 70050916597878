import styled, { css } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

export const StyledDatePickerWrapper = styled.div`
  ${({ theme, isOneWay }) => css`
    & .d-none {
      display: none;
    }

    & input {
      cursor: pointer;
      width: 100%;
      background: transparent;
      font-family: 'sbc-font';
      font-weight: 700;
      font-size: 14px;

      ${({ active }) =>
        active &&
        css`
          color: #590c32;
        `}

      &:focus {
        outline: none;
      }

      &::placeholder {
        transition: ${theme.transition};
        color: ${isOneWay ? theme.colors.gray : 'inherit'};
      }
    }

    & .react-datepicker {
      border: none;
      display: flex;
      border-radius: 0 0 50px 50px;

      &__navigation {
        position: absolute;
        top: 3.5rem;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: none;
        background: linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
        box-shadow: 0px 4px 10px rgb(0 0 0 / 19%);
        cursor: pointer;

        @media ${theme.breakpoints.xl} {
          top: 100%;
        }

        &--next {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEuMzMzNSAxLjY2Njc1TDYuNjY2ODMgNy4wMDAwOEwxLjMzMzUgMTIuMzMzNCIgc3Ryb2tlPSIjRjRGNkY3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDwvc3ZnPgo='),
            linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
          background-position: center;
          background-repeat: no-repeat;
          @media ${theme.breakpoints.xl} {
            right: 0;
          }

          &--disabled {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEuMzMzNSAxLjY2Njc1TDYuNjY2ODMgNy4wMDAwOEwxLjMzMzUgMTIuMzMzNCIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDwvc3ZnPgo=');
            background-color: #e6e8ea;
            box-shadow: none;
            cursor: default;
          }
        }
        &--previous {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTYuNjY2NSAxMi4zMzMzTDEuMzMzMTcgNi45OTk5Mkw2LjY2NjUgMS42NjY1OSIgc3Ryb2tlPSIjRjRGNkY3IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDwvc3ZnPgo='),
            linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
          background-position: center;
          background-repeat: no-repeat;
          @media ${theme.breakpoints.xl} {
            left: 0;
          }

          &--disabled {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTYuNjY2NSAxMi4zMzMzTDEuMzMzMTcgNi45OTk5Mkw2LjY2NjUgMS42NjY1OSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDwvc3ZnPgo=');
            background-color: #e6e8ea;
            box-shadow: none;
            cursor: default;
          }
        }
      }

      &__header {
        background: none;
        display: flex;
        flex-direction: column;
        border-bottom: none;

        @media ${theme.breakpoints.xl} {
          display: block;
          border-bottom: 1px solid #f4f6f7;
        }
      }

      &__current-month {
        order: 3;
        margin: 1.25rem 0;

        @media ${theme.breakpoints.xl} {
          order: unset;
        }
      }

      &__day-names {
        order: 1;
        border-bottom: 1px solid #f4f6f7;

        @media ${theme.breakpoints.xl} {
          order: unset;
          border-bottom: unset;
          margin-bottom: 0;
        }
      }

      &__day {
        font-family: 'sbc-font';
        font-size: 12px;
        font-weight: 700;
        line-height: 33px;
        color: #333333;

        width: 3.0625rem;
        border-radius: 0px;
        margin: 0.5px 0;
        transform-style: preserve-3d;

        outline: none;
        background: transparent;

        & .day-number {
          background: transparent;
          width: 1rem;
          height: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 1rem;
          margin: 0 auto;
        }

        &:hover {
          background: none;
          border-radius: 0;

          & .day-number {
            background: linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            padding: 1rem;
            margin: 0 auto;
          }
        }

        &-name {
          width: 3.0625rem;
          height: 33px;
          padding: 5px;
          color: #b4b4b4;
          margin: 0;
        }

        &--disabled {
          color: #b4b4b4;

          &:hover .day-number {
            color: #b4b4b4;
            background: none;
          }
        }

        &--in-range {
          color: #fff;
          background: #1d0f58;
          background: linear-gradient(
            to right,
            #1d0f58,
            #1d0f58 100%,
            transparent 100%,
            transparent 100%
          );
        }

        &--range-end {
          background: linear-gradient(
            to right,
            #1d0f58,
            #1d0f58 50%,
            transparent 50%,
            transparent 100%
          );
          & .day-number {
            background: linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            padding: 1rem;
            margin: 0 auto;
          }
        }

        &--range-start {
          background: linear-gradient(
            to left,
            #1d0f58,
            #1d0f58 50%,
            transparent 50%,
            transparent 100%
          );
        }

        &--selected {
          & .day-number {
            background: linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            padding: 1rem;
            margin: 0 auto;
          }
        }

        &--outside-month {
          visibility: hidden;
        }
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month--selecting-range
        .react-datepicker__day--in-range {
        color: #fff;
        background: #1d0f58;
        border-radius: 0;
      }

      &
        .react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
      .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range {
        background: linear-gradient(
          to left,
          #1d0f58,
          #1d0f58 50%,
          transparent 50%,
          transparent 100%
        );
      }
      & .react-datepicker__day--range-end.react-datepicker__day--in-range,
      .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end,
      .react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end,
      .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
        background: linear-gradient(
          to right,
          #1d0f58,
          #1d0f58 50%,
          transparent 50%,
          transparent 100%
        );
      }
      &
        .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
        background: linear-gradient(to left, #1d0f58, #1d0f58 100%);
      }
      &
        .react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
        background: linear-gradient(to right, #1d0f58, #1d0f58 100%);
      }
      &
        .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end,
      .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
        background: none;
      }
    }
  `}
`
