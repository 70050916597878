import styled, { css } from 'styled-components'

export const Grid = styled.div`
  ${(props) => css`
    display: grid;
    grid-template-columns: ${props.template || 'auto'};
    align-items: ${props.align || 'start'};
    justify-content: ${props.justify || 'start'};
    grid-gap: ${props.gap || 0};
  `}
`
