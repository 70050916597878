import * as yup from 'yup'

const inputs = {
  from: {
    label: '',
    isValid: false
  },
  to: {
    label: '',
    isValid: false
  }
}

const calendars = {
  departure: null,
  return: null
}

const passengers = {
  adults: 1,
  childrens: 0,
  infants: 0
}

const tripClass = 'business'

const tripType = 1

export const defaultValues = {
  ...inputs,
  ...calendars,
  passengers, // keep it as separate obj
  tripClass,
  tripType
}

export const validationSchema = yup.object().shape({
  from: yup.object().shape({
    label: yup.string().required(`Please enter a “From” city.`).min(1)
  }),
  to: yup.object().shape({
    label: yup.string().required(`Please enter a “To” city.`).min(1)
  }),
  departure: yup
    .date()
    .nullable(true)
    .when('tripType', {
      is: (value) => Boolean(value === 1),
      then: yup.date().required(`Please enter a “Departure” date.`),
      otherwise: yup.date().nullable(true)
    }),
  return: yup
    .date()
    .nullable(true)
    .when('tripType', {
      is: (value) => Boolean(value === 0),
      then: yup.date().required(`Please enter a “Return” date.`),
      otherwise: yup.date().nullable(true)
    })
}).test('from-to-not-equal', 'From and To cities cannot be the same', function(value) {
    const { from, to } = value;
    return from.code !== to.code;
})
