import styled, { css } from 'styled-components'

import { Image } from '../atoms'

import { FormInput } from '.'

export const TextInputBase = ({
  imgSrc,
  imgAlt,
  inputLabel,
  inputId,
  inputName,
  inputPlaceholder,
  inputRegister,
  inputType,
  inputOnChange,
  inputOnFocus,
  inputOnBlur
}) => {
  return (
    <StyledFlexWrapper>
      <StyledImageWrapper>
        <Image src={imgSrc} alt={imgAlt} />
      </StyledImageWrapper>
      <FormInput
        label={inputLabel}
        id={inputId}
        name={inputName}
        placeholder={inputPlaceholder}
        register={inputRegister}
        type={inputType}
        onChange={inputOnChange}
        onFocus={inputOnFocus}
        onBlur={inputOnBlur}
      />
    </StyledFlexWrapper>
  )
}

const StyledFlexWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: 1px solid #b4b4b4;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    `}
`

const StyledImageWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.75rem;
    width: 1.75rem;

    @media ${theme.breakpoints.md} {
      height: 2.25rem;
      width: 2.25rem;
    }
  `}
`
