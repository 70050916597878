import styled from 'styled-components'

const namesMap = {
  'passengers.adults': {
    icon: (
      <svg
        className="h-7 fill-gradient"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 28"
      >
        <path
          fillRule="evenodd"
          d="M6.681 5.512c1.542 0 2.796-1.236 2.796-2.756C9.477 1.236 8.223 0 6.68 0 5.14 0 3.887 1.236 3.887 2.756c0 1.52 1.253 2.756 2.794 2.756zm6.666 8.792a.347.347 0 00.009-.172l-1.391-7.277a1.404 1.404 0 00-1.396-1.175H2.794A1.401 1.401 0 001.4 6.846L.007 14.132a.35.35 0 00.009.172c-.01.064-.016.13-.016.194a1.417 1.417 0 002.81.245l.879-5.411L3.677 26.6a1.417 1.417 0 002.836 0v-8.547h.338V26.6a1.417 1.417 0 002.834 0l-.01-17.268.878 5.42a1.418 1.418 0 002.81-.254c0-.065-.006-.13-.016-.194z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    title: 'Adult',
    description: 'Ages 13 or above'
  },
  'passengers.childrens': {
    icon: (
      <svg
        className="h-6 fill-gradient"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 23"
      >
        <path
          fillRule="evenodd"
          d="M5 5.87c.585 0 1.157-.17 1.643-.493a2.94 2.94 0 001.09-1.317 2.916 2.916 0 00-.64-3.2A2.977 2.977 0 003.871.223a2.952 2.952 0 00-1.327 1.081A2.92 2.92 0 002.91 5.01c.554.55 1.306.86 2.09.86zm.884.551a2.518 2.518 0 00-1.769 0c-.774.233-3.69 1.477-4.112 7.044a.924.924 0 00.512.9.942.942 0 001.06-.148.929.929 0 00.297-.613c.096-1.268.332-2.243.628-2.99v2.867c0 .191.022.382.066.568v7.858a1.08 1.08 0 00.674 1.01 1.1 1.1 0 001.433-.592c.054-.133.082-.275.08-.418v-5.954a2.565 2.565 0 00.493 0v5.954a1.08 1.08 0 00.674 1.01 1.1 1.1 0 001.432-.592 1.08 1.08 0 00.082-.418V14.05c.044-.186.066-.377.066-.568v-2.868c.296.748.531 1.722.627 2.991a.929.929 0 00.297.613.942.942 0 001.53-.392.925.925 0 00.043-.36c-.42-5.569-3.34-6.812-4.113-7.044z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    title: 'Childrens',
    description: 'Ages 2-12'
  },
  'passengers.infants': {
    icon: (
      <svg
        className="h-5 fill-gradient"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 22"
      >
        <path
          fillRule="evenodd"
          d="M10.612 13.934s2.047 2.106 2.053 2.109c.652.676.482 1.863-.136 2.509l-2.855 3.057c-1.101 1.13-2.576-.439-1.505-1.533l1.812-1.862-1.774-1.808 2.405-2.472zm-6.364 0s-2.043 2.106-2.043 2.109c-.658.676-.489 1.863.123 2.509l2.866 3.057c1.1 1.13 2.572-.439 1.507-1.533l-1.808-1.862 1.76-1.808-2.405-2.472zm3.254-7.832c1.638 0 2.969-1.363 2.969-3.055C10.471 1.364 9.141 0 7.501 0 5.862 0 4.53 1.364 4.53 3.047c0 1.69 1.33 3.055 2.973 3.055zm3.217 6.61l.042-2.081 2.469 2.366c.191.187.448.291.716.29a1.037 1.037 0 00.768-.341 1.103 1.103 0 00-.05-1.527s-2.83-3.16-3.236-3.528c-.753-.686-1.857-1.075-3.647-1.075h-.563c-1.792 0-2.895.389-3.65 1.073-.405.369-3.235 3.53-3.235 3.53a1.102 1.102 0 00-.05 1.527 1.037 1.037 0 001.485.051l2.468-2.365.043 2.082h6.44v-.002z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    title: 'Infants',
    description: 'Under 2'
  }
}

export const PassengersDescription = ({ name }) => (
  <StyledPassengersItem>
    <StyledIcon>{namesMap[name].icon}</StyledIcon>
    <StyledPassengersDescription>
      <StyledTitle>{namesMap[name].title}</StyledTitle>
      <StyledDescription>{namesMap[name].description}</StyledDescription>
    </StyledPassengersDescription>
  </StyledPassengersItem>
)

const StyledPassengersItem = styled.div`
  display: flex;
`

const StyledPassengersDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-weight: 700;
  line-height: 1;
  margin-left: 0.875rem;
`

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 36px;
  height: 2.25rem;
  width: 16px;
  width: 1rem;
`

const StyledTitle = styled.span`
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
`

const StyledDescription = styled.span`
  font-size: 10px;
  font-size: 0.625rem;
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity));
`
