import styled from 'styled-components'

export const CounterButton = ({ disabled, onClick, icon }) => (
  <StyledCounterButton disabled={disabled} onClick={onClick}>
    {icon}
  </StyledCounterButton>
)

const StyledCounterButton = styled.button`
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  --tw-gradient-from: #590c32;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(89, 12, 50, 0));
  --tw-gradient-to: #9d1d5a;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 36px;
  height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 36px;
  width: 2.25rem;

  &:disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 180, 180, var(--tw-bg-opacity));
    background-image: none;
    --tw-bg-opacity: 0.2;
    cursor: default;
    --tw-text-opacity: 1;
    color: rgba(131, 141, 149, var(--tw-text-opacity));
  }
`
