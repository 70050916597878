import { device } from '../constants/breakpoints'

export const theme = {
  breakpoints: device,

  colors: {
    white: '#FFF',
    grey: '#B4B4B4',
    whiteGray: '#F4F6F6',
    black: '#333333'
  },

  cubic: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
  transition: '0.2s all cubic-bezier(0.65, 0.05, 0.36, 1)',
  gradient: 'linear-gradient(242.97deg, #590C32 0%, #9D1D5A 100%)',
  boxShadow: '0px 14px 14px rgba(0, 0, 0, 0.25)',
  borderRadius: '30px',
  borderRadiusFull: '50%',

  spacing: '0.625rem'
}

// var(--tw-ring-offset-shadow,0 0 transparent), var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
