import styled from 'styled-components'

export const Image = ({ src, alt, imageClassName, containerClassName }) => (
  <StyledImageContainer className={containerClassName}>
    <StyledImage className={imageClassName} src={src} alt={alt} />
  </StyledImageContainer>
)

const StyledImageContainer = styled.div`
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const StyledImage = styled.img``
