import styled from 'styled-components'

const Svg = styled.svg`
  width: 1em;
  height: 1em;
  ${({ css }) => css && css};
`

const reqSvgs = require.context('../icons', true, /\.svg$/)

const requireAll = (requireContext) =>
  requireContext.keys().map((path) => ({ path, file: reqSvgs(path).default }))

const icons = requireAll(reqSvgs).reduce((state, icon) => {
  // console.log(icon)
  return {
    ...state,
    [icon?.path.match(/\/(.*?)\./)[1]]: icon.file
  }
}, {})

// console.log('All icons name:', icons)

export const Icon = ({ name, css, className, fill, onClick = () => {} }) => {
  const SvgIcon = icons[name]

  return (
    // <object type="image/svg+xml" data={icons[name]} />
    // <Svg {...{ key: name, css, className, fill, onClick }}>
    <img src={icons[name]} width="100%" height="100%" />
    // </Svg>
  )
}
