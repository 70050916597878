import styled, { css } from 'styled-components'

export const CheckboxSlider = ({ checked, onChange }) => {
  return (
    <StyledLabel>
      <input type="checkbox" onChange={onChange} checked={checked} />
      <i />
      One way
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  font-family: sbc-font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #7a7b7b;

  display: flex;
  align-items: center;

  & i {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 46px;
    height: 26px;
    background-color: #dcdcdc;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: #dcdcdc;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 11px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:active i:after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }

  &:active input:checked + i:after {
    transform: translate3d(16px, 2px, 0);
  }

  & input {
    display: none;

    &:checked + i {
      background: linear-gradient(242.97deg, #590c32 0%, #9d1d5a 100%);

      &:before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }
      &:after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }
`
