import { createEvent, createStore, createEffect, forward } from 'effector'

import { URL } from '../constants/autocomplete-url'

const fetchSearchTerm = createEffect(async (term) => {
  if (term.length >= 2) {
    const SEARCH_URL = `${URL[process.env.NODE_ENV]}?term=${term}`
    const req = await fetch(SEARCH_URL)
    return req.json()
  } else {
    return resetSearchTermEvent()
  }
})

fetchSearchTerm.fail.watch(console.error)

export const fetchSearchTermEvent = createEvent()
export const resetSearchTermEvent = createEvent()

export const setLastTermEvent = createEvent()
export const setActiveFieldEvent = createEvent()

export const $lastTerm = createStore({
  from: null,
  to: null
}).on(setLastTermEvent, (state, value) => ({ ...state, ...value }))

export const $activeField = createStore(null).on(
  setActiveFieldEvent,
  (_, name) => name
)

export const $searchOptions = createStore([])
  .on(fetchSearchTerm.doneData, (_, data) => data?.group)
  .reset(resetSearchTermEvent)

forward({
  from: fetchSearchTermEvent,
  to: fetchSearchTerm
})

forward({
  from: $lastTerm.updates,
  to: resetSearchTermEvent
})
