import styled from 'styled-components'

import { Label, Input } from '../atoms'

export const FormInput = ({
  label,
  id,
  name,
  placeholder,
  register,
  type,
  readOnly,
  onChange,
  onBlur = () => null,
  value,
  onFocus = () => null
}) => (
  <StyledFormInputContainer>
    <Label htmlFor={id} text={label} />
    <Input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      register={register}
      readOnly={readOnly}
      onChange={onChange}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  </StyledFormInputContainer>
)

const StyledFormInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-grow: 1; */
  /* margin-left: 0.5rem; */
`
