import styled from 'styled-components'

export const Input = ({
  type,
  name,
  id,
  className,
  placeholder,
  value,
  register,
  readOnly,
  onChange,
  onBlur,
  onFocus
}) => (
  <StyledInput
    {...{
      type,
      name,
      id,
      className,
      placeholder,
      value,
      ref: register,
      readOnly,
      onChange: (e) => onChange(e),
      onFocus: (e) => onFocus(e),
      onBlur: (e) => onBlur(e)
    }}
  />
)

const StyledInput = styled.input`
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  width: 100%;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`
