import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Image } from '../atoms'
import { useOnClickOutside } from '../hooks'
import { FormInput, TripClassSwitch } from '../molecules'

import { PassengersItem } from '.'

export const Passengers = ({
  inputLabel,
  inputId,
  inputPlaceholder,
  inputRegister,
  inputType,
  inputReadOnly,
  register,
  setValue,
  getValues,
  control
}) => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [totalPassengers, setTotalPassengers] = useState(1)
  const [disabled, setDisabled] = useState({
    adults: { increase: false, decrease: true },
    childrens: { increase: false, decrease: true },
    infants: { increase: false, decrease: true }
  })

  useEffect(() => {
    const sum = Object.values(getValues('passengers')).reduce(
      (sum, val) => +sum + +val,
      0
    )
    setTotalPassengers(sum)
  })

  const onChangePassengersState = (adults, childrens, infants) => {
    const total = adults + childrens + infants
    const maxInfants = adults + childrens

    const disableAdultsIncrease = adults >= 9 || total >= 9
    const disableChildrensIncrease = childrens >= 9 || total >= 9
    const disableInfantsIncrease = infants >= maxInfants || total >= 9

    const disableAdultsDecrease = maxInfants <= 1 || adults <= 0
    const disableChildrensDecrease = maxInfants <= 1 || childrens <= 0
    const disableInfantsDecrease = infants <= 0 || total <= 1

    setDisabled({
      adults: {
        increase: disableAdultsIncrease,
        decrease: disableAdultsDecrease
      },
      childrens: {
        increase: disableChildrensIncrease,
        decrease: disableChildrensDecrease
      },
      infants: {
        increase: disableInfantsIncrease,
        decrease: disableInfantsDecrease
      }
    })
  }

  const setOpenPassengers = (value) => {
    if(value) {
      const passengers = getValues('passengers')
      onChangePassengersState(Number(passengers.adults), Number(passengers.childrens), Number(passengers.infants))
    }

    setIsOpen(value)
  }

  useOnClickOutside(ref, () => setOpenPassengers(false))



  const onChangeHandler = (name, increase) => {
    const values = Object.entries(getValues('passengers')).reduce(
      (values, [key, value]) => ({ ...values, [key]: +value }),
      {}
    )

    let { adults, childrens, infants } = {
      ...values,
      [name]: increase ? +values[name] + 1 : +values[name] - 1
    }

    infants = Math.min(adults + childrens, infants)

    onChangePassengersState(adults, childrens, infants)

    setValue('passengers', { adults, childrens, infants })
  }

  return (
    <StyledRelativeWrapper>
      <StyledFormFieldText onClick={() => setOpenPassengers(true)}>
        <StyledImageWrapper>
          <svg
            className="w-6 h-6 fill-current text-grey-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fillRule="evenodd"
              d="M16 7.107c1.538 0 2.789-1.295 2.789-2.887s-1.252-2.887-2.79-2.887-2.788 1.295-2.788 2.887 1.25 2.887 2.788 2.887zm6.65 9.21a.38.38 0 00.01-.179l-1.39-7.624a1.489 1.489 0 00-.479-.885 1.361 1.361 0 00-.913-.345H12.12a1.358 1.358 0 00-.91.341 1.485 1.485 0 00-.48.88l-1.39 7.633a.384.384 0 00.009.18 1.31 1.31 0 00-.016.204c.005.368.14.72.379.99a1.368 1.368 0 001.89.173c.279-.222.47-.545.535-.907l.876-5.669-.011 18.09c.005.391.156.764.42 1.039.265.274.622.428.994.428.373 0 .73-.154.995-.428.264-.275.415-.648.42-1.038v-8.955h.338V29.2c.005.39.157.761.421 1.035.265.273.622.427.993.427s.728-.154.992-.427a1.52 1.52 0 00.422-1.036l-.012-18.09.878 5.678c.067.36.26.681.54.902a1.367 1.367 0 001.886-.179c.238-.269.372-.621.377-.988 0-.069-.006-.137-.016-.204z"
              clipRule="evenodd"
            ></path>
          </svg>
        </StyledImageWrapper>

        <FormInput
          label={inputLabel}
          value={
            totalPassengers !== 1
              ? `${totalPassengers} Passengers`
              : `1 Passenger`
          }
          type="text"
          readOnly
        />
      </StyledFormFieldText>

      {isOpen && (
        <AbsoluteWrapper ref={ref}>
          <PassengersCard>
            {['adults', 'childrens', 'infants'].map((name) => (
              <PassengersItem
                disabled={disabled[name]}
                register={register}
                name={`passengers.${name}`}
                onChangeHandler={(increase) => onChangeHandler(name, increase)}
              />
            ))}
            <ClassTitle>Select Class</ClassTitle>
            <TripClassSwitch control={control} name="tripClass" />
          </PassengersCard>
        </AbsoluteWrapper>
      )}
    </StyledRelativeWrapper>
  )
}

const StyledRelativeWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    background: ${theme.colors.white};
    padding: ${theme.spacing};
    border-radius: ${theme.borderRadius};
  `}
`
const StyledFormFieldText = styled.div`
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  grid-gap: 0.5rem;
  align-items: center;

  cursor: pointer;
  position: relative;
`

const StyledImageWrapper = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const AbsoluteWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    @media ${theme.breakpoints.xl} {
      right: 1rem;
      left: unset;
      width: unset;
    }
  `}
`
const PassengersCard = styled.div.attrs({ className: 'card' })`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 1.75rem;
    background-color: #fff;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), 0 14px 14px rgba(0, 0, 0, 0.05);

    @media ${theme.breakpoints.xl} {
      width: 24rem;
      height: 18rem;
      margin: -1.5rem;
      padding: 1.5rem;
      border-radius: 1.75rem;
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      --tw-shadow: 0 14px 14px rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
        var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
    }
  `}
`
const ClassTitle = styled.p`
  margin-top: 2.5rem;
  font-family: sbc-font;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;

  text-align: center;

  color: #333333;
`
