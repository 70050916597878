import { Controller } from 'react-hook-form'
import styled from 'styled-components'

const tripClassItems = [
  { id: 'premium', value: 'premium', label: 'premium' },
  { id: 'business', value: 'business', label: 'business' },
  { id: 'first', value: 'first', label: 'first' }
]

export const TripClassSwitch = ({ name, control }) => (
  <StyledTripClass>
    <StyledTripClassSwitch>
      <Controller
        control={control}
        name={name}
        render={(field) =>
          tripClassItems.map((item) => (
            <StyledTripClassSwitchItem key={item.id}>
              <StyledRadioButton
                checked={item.value === field.value}
                ref={field.ref}
                name={name}
                value={item.value}
                id={item.id}
                onChange={() => field.onChange(item.value)}
              />
              <StyledTripClassSwitchLabel htmlFor={item.id}>
                {item.label}
              </StyledTripClassSwitchLabel>
            </StyledTripClassSwitchItem>
          ))
        }
      />
    </StyledTripClassSwitch>
  </StyledTripClass>
)

const StyledTripClass = styled.div`
  margin-top: 0.5rem;
`

const StyledTripClassSwitch = styled.ul`
  --tw-bg-opacity: 1;
  background-color: rgba(244, 246, 247, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
`
const StyledTripClassSwitchItem = styled.li`
  display: flex;
  flex-grow: 1;
  height: 32px;
  height: 2rem;
`
const StyledTripClassSwitchLabel = styled.label`
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  height: 2rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 10;

  &:before {
    content: '';
    z-index: -1;
    border-radius: 9999px;
    height: 100%;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
`
const StyledRadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;

  &:checked + ${StyledTripClassSwitchLabel} {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));

    &:before {
      background-image: linear-gradient(
        to bottom left,
        var(--tw-gradient-stops)
      );
      --tw-gradient-from: #590c32;
      --tw-gradient-stops: var(--tw-gradient-from),
        var(--tw-gradient-to, rgba(89, 12, 50, 0));
      --tw-gradient-to: #9d1d5a;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      opacity: 1;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
    }
  }
`
