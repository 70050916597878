import { yupResolver } from '@hookform/resolvers/yup'
import { useStore } from 'effector-react'
import ReactDOM from 'react-dom'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { $activeField } from '../app-state/text-fields'
import { ErrorPopup, Grid, SubmitButton } from '../atoms'
import { breakpoints } from '../constants/breakpoints'
import { defaultValues, validationSchema } from '../constants/form-default-values'
import { useWindowSize } from '../hooks'
import { TripTypeSelector } from '../molecules'
import { TripClassSelector } from '../molecules/trip-class-selector'
import { FormFieldText, DatePickerField, Passengers } from '../organisms'

const getDefaultValues = () => {
  try {
    const savedData = JSON.parse(window.localStorage.getItem('form-data'))
    if (savedData !== null && new Date(savedData.departure) >= new Date()) {
      const startDate = new Date(savedData?.departure)
      const endDate = new Date(savedData?.return)

      return { ...savedData, departure: startDate, return: endDate }
    } else {
      return defaultValues
    }
  }
  catch {
    return defaultValues
  }
}

export const SearchTemplate = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    errors,
    clearErrors
  } = useForm({
    shouldUnregister: false,
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema),
    shouldFocusError: false
  })

  const activeField = useStore($activeField)

  const { width } = useWindowSize()
  const isMobile = width <= breakpoints.xl

  return (
    <Grid gap="1rem" template="1fr">
      <TripClassSelector {...{ name: 'tripClass', control }}/>

      <TripTypeSelector {...{ name: 'tripType', control }}/>

      <StyledSearchForm data-aos="zoom-in-down" data-aos-easing="ease-out-expo" data-aos-delay="200" data-aos-duration="1500">
        <StyledCitiesGroup>
          <FormFieldText
            {...{
              inputLabel: 'from',
              inputId: 'search-form-input-from-null',
              inputName: 'from',
              inputPlaceholder: 'Flying from?',
              inputRegister: register,
              // inputRegister: {
              //   ...register('from.label', {
              //     required: true,
              //     validate: (label) => Boolean(label.length)
              //   })
              // },
              inputType: 'text',
              setValue,
              getValues,
              showSwitch: true,
              control
            }}
          />

          <StyledSwitchButton
            hide={Boolean(activeField)}
            isMobile={isMobile}
            onClick={() => {
              const values = getValues(['from', 'to'])
              setValue('from', { ...values.to })
              setValue('to', { ...values.from })
            }}
          >
            <svg
              className="w-5 h-5 md:w-6 md:h-6 fill-current text-grey-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 23"
            >
              <path
                d="M17.25 3.833l-3.832 3.833h2.875v6.709a1.916 1.916 0 11-3.834 0V7.666a3.838 3.838 0 00-3.833-3.833 3.838 3.838 0 00-3.834 3.833v6.709H1.917l3.834 3.833 3.833-3.833H6.71V7.666a1.916 1.916 0 113.834 0v6.709a3.838 3.838 0 003.833 3.833 3.838 3.838 0 003.833-3.833V7.666h2.875l-3.833-3.833z"
                fill="#FFF"
              />
            </svg>
          </StyledSwitchButton>

          <FormFieldText
            {...{
              inputLabel: 'to',
              inputId: 'search-form-input-to-null',
              inputName: 'to',
              inputPlaceholder: 'Where are you flying?',
              inputRegister: register,
              // inputRegister: {
              //   ...register('to.label', {
              //     required: true,
              //     validate: (label) => Boolean(label.length)
              //   })
              // },
              inputType: 'text',
              setValue,
              getValues,
              showSwitch: false,
              control
            }}
          />
        </StyledCitiesGroup>

        <StyledGroup>
          <DatePickerField
            setValue={setValue}
            getValues={getValues}
            control={control}
          />
          <Passengers
            {...{
              inputLabel: 'passengers',
              inputId: 'search-form-date-return',
              inputPlaceholder: '1 Passenger',
              inputRegister: register,
              inputType: 'text',
              inputReadOnly: true,
              register,
              setValue,
              getValues,
              control
            }}
          />
        </StyledGroup>

        <StyledActions>
          <SubmitButton submit={handleSubmit} />
        </StyledActions>
      </StyledSearchForm>
      {/*{console.log(errors)}*/}
      {Boolean(Object.keys(errors).length) &&
        ReactDOM.createPortal(
          <ErrorPopup errors={errors} clearErrors={clearErrors} />,
          document.querySelector('body')
        )}
    </Grid>
  )
}

const StyledSearchForm = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing};

    background-color: transparent;
    padding: 0 ${theme.spacing};

    z-index: 10;

    @media ${theme.breakpoints.xl} {
      grid-gap: 0;
      align-items: center;
      border-radius: ${theme.borderRadius};
      grid-template-columns: 32rem auto 60px;
      background-color: ${theme.colors.white};
      padding: 0.25rem;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
        var(--tw-ring-shadow, 0 0 transparent),
        0 0 24px 10px rgba(0, 0, 0, 0.25);
    }
  `}
`

const StyledCitiesGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    position: relative;

    @media ${theme.breakpoints.xl} {
      grid-template-columns: repeat(2, 1fr);
      /* grid-gap: ${theme.spacing}; */
    }
  `}
`

const StyledGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    display: grid;
    grid-gap: ${theme.spacing};

    @media ${theme.breakpoints.xl} {
      grid-template-columns: 2fr 1fr;
      grid-gap: 0;
    }
  `}
`

const StyledActions = styled.div`
  flex: none;
  margin-top: 0;
`

const StyledSwitchButton = styled.button`
  ${({ theme, isMobile, hide }) => css`
    cursor: pointer;
    background: ${theme.gradient};
    color: ${theme.colors.white};
    border-radius: ${theme.borderRadiusFull};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.25rem;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(50% + 10px);

    ${isMobile &&
    css`
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 15;

      visibility: ${hide ? 'hidden' : 'unset'};
    `}

    svg {
      ${!isMobile &&
      css`
        transform: rotate(90deg);
      `}
    }
  `}
`
