import { createRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useOnClickOutside } from '../hooks'
import { ReactComponent as CloseIcon } from '../icons/close.svg'
const keys = [32, 33, 34, 35, 36, 37, 38, 39, 40]

function preventDefault(e) {
  e.preventDefault()
}

function preventDefaultForScrollKeys(e) {
  if (keys.includes(e.keyCode)) {
    preventDefault(e)
    return false
  }
}

var supportsPassive = false
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true
      }
    })
  )
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false
var wheelEvent =
  'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

export const ErrorPopup = ({ errors, clearErrors }) => {
  const ref = createRef()
  useEffect(() => {
    window.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false)
    return () => {
      window.removeEventListener('DOMMouseScroll', preventDefault, false) // older FF
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
      window.removeEventListener('touchmove', preventDefault, wheelOpt) // mobile
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
    }
  })
  useOnClickOutside(ref, () => clearErrors())
  return (
    <StyledBackDrop>
      <StyledContainer ref={ref}>
        <StyledErrors>
          {Object.values(errors).map((error) => (
            <p>{error?.label?.message}</p>
          ))}
          {Object.values(errors).map((error) => (
            <p>{error?.message}</p>
          ))}
        </StyledErrors>

        <ButtonGroup>
          <DeclineBtn onClick={() => clearErrors()}>Ok</DeclineBtn>
        </ButtonGroup>

        <StyledCloseBtn onClick={() => clearErrors()}>
          <CloseIcon />
        </StyledCloseBtn>
      </StyledContainer>
    </StyledBackDrop>
  )
}

const StyledBackDrop = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    transition-duration: 75ms;
    transition-property: opacity;
  `}
`

const StyledContainer = styled.div`
  ${({ theme }) => css`
    width: 37.5rem;
    background: #ffffff;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
    padding: 4rem;
    position: relative;
    margin: 0.625rem;
  `}
`

const StyledHeaderText = styled.h3`
  font-family: sbc-font;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 110%;
  /* or 21px */

  text-align: center;

  color: #000000;
`

const StyledHeaderDescription = styled.h5`
  font-family: sbc-font;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 110%;
  /* or 21px */

  text-align: center;

  color: #000000;
`

const StyledErrors = styled.div``

const StyledCloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 25px;
    right: 25px;

    width: 45px;
    height: 45px;
    background: #dbeafb;
    border-radius: 39px;

    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`

const ButtonBase = styled.button`
  font-family: sbc-font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;

  padding: 1rem;

  text-align: center;

  width: 145px;

  border-radius: 50px;
  box-shadow: 0px 4px 15px rgba(68, 85, 234, 0.1);
`

const AcceptBtn = styled(ButtonBase)`
  color: #fff;
  background: #00c908;
  margin-bottom: 22px;
`

const DeclineBtn = styled(ButtonBase)`
  color: #4a94ec;
  background: rgba(74, 148, 236, 0.1);
`
