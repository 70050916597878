import styled from 'styled-components'

export const AirPortItem = ({ image, name, country, code, onClick }) => {
  return (
    <StyledAirPortCardItem
      onClick={(e) => onClick(e, { label: `${name} (${code})`, code })}
    >
      <StyledSubIndicator>
        <svg
          className="h-4 stroke-current text-grey-600 group-hover:text-grey-200 -mt-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 19"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 18l4-4.667L9 8"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.333 13.333H1V1"
          />
        </svg>
      </StyledSubIndicator>
      <StyledAirPortIcon>
        <svg
          className="w-4 fill-current text-grey-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <path d="M15.71.29c.335.397.38.96.137 1.69a4.982 4.982 0 01-1.233 1.964l-1.838 1.838 1.827 7.946c.038.145-.008.27-.137.377L13.005 15.2a.322.322 0 01-.217.068.253.253 0 01-.08-.011.316.316 0 01-.24-.183l-3.185-5.8-2.957 2.957.605 2.215a.348.348 0 01-.091.354l-1.096 1.096A.356.356 0 015.48 16h-.023a.41.41 0 01-.274-.148l-2.158-2.877L.15 10.817c-.083-.053-.133-.14-.148-.263a.391.391 0 01.103-.285L1.2 9.16a.356.356 0 01.262-.102c.046 0 .076.004.092.011l2.215.605 2.956-2.957-5.8-3.185a.382.382 0 01-.193-.274.352.352 0 01.102-.308L2.296 1.49c.106-.1.22-.13.342-.092l7.592 1.815 1.827-1.826A4.983 4.983 0 0114.02.154c.73-.244 1.293-.198 1.69.137z" />
        </svg>
      </StyledAirPortIcon>

      <StyledCountryLabel>
        <StyledAirportName>{name}</StyledAirportName>
        <StyledCityName>{country}</StyledCityName>
      </StyledCountryLabel>

      <StyledCodeButton>
        <StyledCodeText>{code}</StyledCodeText>
        <svg
          class="w-6 h-6 stroke-gradient group-hover:text-grey-200 group-hover:stroke-current ml-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          ></path>
        </svg>
      </StyledCodeButton>
    </StyledAirPortCardItem>
  )
}

const StyledSubIndicator = styled.div`
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  height: 2.25rem;
  width: 36px;
  width: 2.25rem;
  flex: none;
`

const StyledAirPortIcon = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 241, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  height: 2.25rem;
  width: 36px;
  width: 2.25rem;
`

const StyledCountryLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1;
  margin-left: 0.625rem;
`
const StyledAirportName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
`
const StyledCityName = styled.div`
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(113, 119, 125, var(--tw-text-opacity));
`
const StyledCodeButton = styled.button`
  display: flex;
  align-items: center;
`
const StyledCodeText = styled.div`
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  --tw-gradient-from: #590c32;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(89, 12, 50, 0));
  --tw-gradient-to: #9d1d5a;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  color: transparent;
  text-transform: uppercase;
`
const StyledAirPortCardItem = styled.div.attrs({ className: 'group' })`
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  padding: 0.625rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
    --tw-gradient-from: #590c32;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(89, 12, 50, 0));
    --tw-gradient-to: #9d1d5a;
    --tw-text-opacity: 1;
    color: rgba(244, 246, 247, var(--tw-text-opacity));

    ${StyledAirportName}, ${StyledCityName}, ${StyledCodeText} {
      --tw-text-opacity: 1;
      color: rgba(244, 246, 247, var(--tw-text-opacity));
    }
  }
`
