import styled from 'styled-components'

export const Label = ({ htmlFor, text, className }) => (
  <StyledLabel htmlFor={htmlFor} className={className}>
    {text}
  </StyledLabel>
)

const StyledLabel = styled.label`
  cursor: pointer;
  font-weight: 700;
  font-size: 10px;
  font-size: 0.625rem;
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity));
  text-transform: uppercase;
`
