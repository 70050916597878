import styled from 'styled-components'

import { Image } from '../atoms'
import { FormInput } from '../molecules'

export const FormFieldDate = ({
  imgSrc,
  imgAlt,
  inputLabel,
  inputId,
  inputName,
  inputPlaceholder,
  inputRegister,
  inputType
}) => (
  <StyledFormFieldText>
    <StyledImageWrapper>
      <Image src={imgSrc} alt={imgAlt} />
    </StyledImageWrapper>
    <FormInput
      label={inputLabel}
      id={inputId}
      name={inputName}
      placeholder={inputPlaceholder}
      register={inputRegister}
      type={inputType}
    />
  </StyledFormFieldText>
)

const StyledFormFieldText = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0.625rem;
  position: relative;

  &:before {
    content: '';
    width: 1px;
    margin-top: -18px;
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 228, var(--tw-bg-opacity));
    height: 2.25rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
`

const StyledImageWrapper = styled.div`
  height: 2.25rem;
  width: 2.25rem;
`
