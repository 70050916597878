import { Controller } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { Grid } from '../atoms'

const tripTypes = [
  { id: 0, text: 'Round trip' },
  { id: 1, text: 'One way' }
]

export const TripTypeSelector = ({ control, name }) => {
  return (
    <Grid justify="center" template="auto auto" gap="25px" data-aos="zoom-in-down" data-aos-easing="ease-out-expo" data-aos-delay="200" data-aos-duration="1500">
      <Controller
        control={control}
        name={name}
        render={({ value, onChange }) =>
          tripTypes.map(({ id, text }) => (
            <StyledButton
              key={id}
              onClick={() => onChange(id)}
              active={value === id}
            >
              {text}
            </StyledButton>
          ))
        }
      />
    </Grid>
  )
}

const StyledButton = styled.button`
  ${({ theme, active }) => css`
    font-family: 'sbc-font';
    font-weight: 700;
    font-size: 14px;
    color: #f4f6f7;
    transition: ${theme.transition};

    opacity: ${active ? 1 : 0.5};
  `}
`
