import styled from 'styled-components'

export const CounterInput = ({ register, name }) => (
  <StyledCounterInput type="text" ref={register} name={name} readOnly />
)

const StyledCounterInput = styled.input`
  text-align: center;
  width: 36px;
  width: 2.25rem;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`
