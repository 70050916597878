import styled from 'styled-components'

import { Image } from '../atoms'

export const CityCardItem = ({
  name,
  country,
  code,
  image,
  airPorts,
  onClick,
  children
}) => {
  return (
    <div>
      <StyledCityCardItem
        onClick={(e) => onClick(e, { label: `${name} (${code})`, code })}
      >
        <StyledCountryImage>
          <Image src={image} />
        </StyledCountryImage>

        <StyledCountryLabel>
          <StyledCityName>{name}</StyledCityName>
          <StyledCountryName>{country}</StyledCountryName>
        </StyledCountryLabel>

        <StyledCodeButton>
          <StyledCodeText>{code}</StyledCodeText>
          <svg
            className="w-6 h-6 stroke-gradient group-hover:text-grey-200 group-hover:stroke-current ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </StyledCodeButton>
      </StyledCityCardItem>

      {children}
    </div>
  )
}

const StyledCountryImage = styled.div`
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  height: 2.25rem;
  width: 36px;
  width: 2.25rem;
  border-radius: 9999px;
  overflow: hidden;
`
const StyledCountryLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1;
  margin-left: 0.625rem;
`
const StyledCountryName = styled.div`
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(113, 119, 125, var(--tw-text-opacity));
`
const StyledCityName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
`
const StyledCodeButton = styled.button`
  display: flex;
  align-items: center;
`
const StyledCodeText = styled.div`
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(270deg, var(--tw-gradient-stops));
  --tw-gradient-from: #590c32;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(89, 12, 50, 0));
  --tw-gradient-to: #9d1d5a;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  color: transparent;
  text-transform: uppercase;
`
const StyledCityCardItem = styled.div.attrs({ className: 'group' })`
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  padding: 0.625rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
    --tw-gradient-from: #590c32;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(89, 12, 50, 0));
    --tw-gradient-to: #9d1d5a;

    ${StyledCityName}, ${StyledCountryName}, ${StyledCodeText} {
      --tw-text-opacity: 1;
      color: rgba(244, 246, 247, var(--tw-text-opacity));
    }
  }
`
