import styled from 'styled-components'

export const PopUpWrapperText = ({ children }) => {
  return <RelativeWrapper>{children}</RelativeWrapper>
}

const RelativeWrapper = styled.div.attrs({ className: 'popOver' })`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  margin: -32px;
  padding: 43px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  width: 150%;
  background: #fff;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.25);

  /* opacity: 1; */
`
