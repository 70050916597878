import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import './index.css'
import { SearchForm } from './search-form'
import { theme } from './theme'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SearchForm />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('search-form')
)

document.dispatchEvent(new Event('ReactDOMRendered'));