import styled from 'styled-components'

import { PassengersDescription, PassengersCounter } from '../molecules'

export const PassengersItem = ({
  disabled,
  register,
  name,
  onChangeHandler
}) => (
  <StyledPassengersItem>
    <PassengersDescription name={name} />
    <PassengersCounter
      disabled={disabled}
      register={register}
      name={name}
      onChangeHandler={onChangeHandler}
    />
  </StyledPassengersItem>
)

const StyledPassengersItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`
// max 9
