import { useState } from 'react'
import styled from 'styled-components'

import { CounterButton, CounterInput, Icon } from '../atoms'

export const PassengersCounter = ({
  disabled,
  register,
  name,
  onChangeHandler
}) => {
  return (
    <StyledPassengersCounter>
      <CounterButton
        disabled={disabled.decrease}
        onClick={() => onChangeHandler(false)}
        icon={
          <svg
            className="w-6 h-6 stroke-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18 12H6"
            ></path>
          </svg>
        }
      />
      <CounterInput register={register} name={name} />
      <CounterButton
        disabled={disabled.increase}
        onClick={() => onChangeHandler(true)}
        icon={
          <svg
            className="w-6 h-6 stroke-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        }
      />
    </StyledPassengersCounter>
  )
}

const StyledPassengersCounter = styled.div`
  display: flex;
`
