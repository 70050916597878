import styled from 'styled-components'

import { CityCardItem, AirPortItem } from '.'

export const AutocompletePopUp = ({ options, onClick }) => (
  <OverflowWrapper>
    {options.map((item) => (
      <CityCardItem key={item.id} onClick={(e) => onClick(e, item)} {...item}>
        {item.airports.map((airport) => (
          <AirPortItem
            key={airport.id}
            onClick={(e) => onClick(e, airport)}
            {...airport}
          />
        ))}
      </CityCardItem>
    ))}
  </OverflowWrapper>
)

const OverflowWrapper = styled.div`
  margin-top: 1rem;
  width: calc(100% + 20px);
  margin: 1rem -10px 0;

  max-height: 18rem;
  overflow-y: scroll;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
